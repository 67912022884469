import { useEffect, useState } from "react";
import { Badge, Card, Table } from "react-bootstrap";


import { Link, useNavigate } from "react-router-dom";

import { usePageTitle } from "../../hooks/usePageTitle";


import ListingService from "../../services/ListingService";
import { Spinner } from "../../components";

export const DraftedPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Manage drafted');
    const [listings, setListings] = useState(null);
    const [spinner, setSpinner] = useState(true);
    const navigate = useNavigate();
    const domain = [
        {
            step: 1,
            to: '/user/listings/domain/' ,
            title: 'Domain'
        },
        {
            step: 2,
            to: '/user/listings/domain-business/' ,
            title: 'Business Information'
        },
        {
            step: 3,
            to: '/user/listings/domain-meta/' ,
            title: 'Meta Information' 
        },
        {
            step: 4,
            to: '/user/listings/domain-description/' ,
            title: 'Description' 
        },
        {
            step: 5,
            to: '/user/listings/domain-cover/' ,
            title: 'Cover Image' 
        }

    ]

    const web = [
        {
            step: 1,
            to: '/user/listings/website/' ,
            title: 'Website URL'
        },
        {
            step: 2,
            to: '/user/listings/business/' ,
            title: 'Business Information' 
        },
        {
            step: 3,
            to: '/user/listings/financial/' ,
            title: 'Financial Information' 
        },
        {
            step: 4,
            to: '/user/listings/monetization/' ,
            title: 'Monetization Method' 
        },
        {
            step: 6,
            to: '/user/listings/meta/' ,
            title: 'Meta Information' 
        },
        {
            step: 7,
            to: '/user/listings/description/' ,
            title: 'Description' 
        },
        {
            step: 8,
            to: '/user/listings/social/' ,
            title: 'Social Following' 
        },
        {
            step: 9,
            to: '/user/listings/images/' ,
            title: 'Banner' 
        }
    ];

    useEffect(()=> {
        ListingService.drafted()
        .then(res => {
            setSpinner(false);
            setListings(res.data)
        })
        .catch(e => {
            setSpinner(false);
            console.log(e);
        })
    }, []);



    const handleResume = (item) => {
        if (item.type === 'domain') {
            if (item.step >= 5 ) {
                navigate(`/user/listings/sell-type/${item.id}`);
                return;
            }
            const step = domain.find(link => link.step === item.step + 1)
            
            navigate(`${step.to}${item.id}`);
        } else {
            if (item.step >= 9 ) {
                navigate(`/user/listings/sell-type/${item.id}`);
                return;
            }
            if (item.step === 4) {
                item.step = 5;
            }
            const step = web.find(link => link.step === item.step + 1)
            if (step) {
                navigate(`${step.to}${item.id}`);
            }
           
        }

        
    }

    return(
        <Card className="shadow-sm border-0">
            <Card.Header className="border-0 h2">
                Drafted Listings

                <Link className="btn btn-sm btn-success float-right " to="/user/selling">Listing</Link>
                <Link className="btn btn-sm btn-success float-right mx-3" to="/user/listings/type">Post a Listing</Link>
            </Card.Header>
            <Card.Body>
            
                <Table>
                    <tbody>
                        {listings && listings.data && listings.data.map(item => {
                            return(
                                <tr key={item.id}>
                                    <td>
                                        <strong>{item.website_business_name || 'N/A' }</strong>
                                        <div>
                                            <Badge className="m-1">{item.type}</Badge>
                                            {item.status === 1 && <Badge className="m-1">Active</Badge>}
                                            
                                        </div>
                                        <div>
                                            
                                        </div>
                                        
                                    </td>
                                    <td className="text-right">
                                        <button onClick={() => handleResume(item)} className="btn btn-outline-primary">Complete Listing</button>
                                    </td>    
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Card.Body>
            {spinner && <Spinner />}
        </Card>
    );
}