import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ListingService from "../../services/ListingService";
import { SellerBiddingDetail } from "../../components/SellerBiddingDetail";
import { Card } from "react-bootstrap";
import { Spinner } from "../../components";

export const SellingDetail = () => {
    const {id} = useParams();
    const [spinner, setSpinner] = useState(true);
    const [listing, setListing] = useState(null);
    useEffect(()=>{
        setSpinner(true)
        ListingService.getOne(id)
        .then(res => {
            setListing(res.data)
            setSpinner(false)
        })
        .catch(e => {
            console.log(e);
        })
    }, []);

    return(

        <Card className="shadow-sm border-0">
            <Card.Header className="border-0 d-flex justify-content-between">
                Offers

                <div>
                    <Link className="btn btn-sm btn-success mx-2" to="/user/selling">Completed</Link>
                    <Link className="btn btn-sm btn-success " to="/user/drafted">Drafted</Link>
                </div>
            </Card.Header>
            <Card.Body>
                { listing && <SellerBiddingDetail data={listing.bids} /> }
            </Card.Body>

            { spinner && <Spinner />}
        </Card>
        
    );
}