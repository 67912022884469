import { Route, Routes } from "react-router-dom";

import { HomeLayout } from '../layout/HomeLayout'
import { PublicLayout } from '../layout/PublicLayout'
import { UserLayout } from "../layout/UserLayout";


import { HomePage,
    LoginPage,
    SignupPage,
    DetailPage, 
    VerifyEmailPage,
    CategoryListingPage,
    AuctionPage as AuctionPagePublic,
    OfferPage as OfferPagePublic,
    ContactPage,
    ForgotPasswordPage,
    ResetLinkSuccessPage,
    ResetPasswordPage,
    TermsPage,
    PrivacyPage,
} from '../pages/public';


import { DashboardPage,
    AuctionPage,
    OfferPage,
    ListingType,
    SellOption,
    AppPage,
    PaymentPage, 
    WebsiteUrl,
    WebsiteFinancial,
    WebsiteMonetization,
    WebsiteIntegrations,
    WebsiteMeta,
    WebsiteDescription,
    WebsiteSocial,
    WebsiteImages,
    WebsiteBusiness,
    DomainUrl,
    DomainBusiness,
    DomainMeta,
    DomainDescription,
    DomainCover,
    SellingPage,
    DraftedPage,
    IntegrationAccount,
    WatchPage,
    BuyPage,
    ConversationPage,
    ThreadPage,
    SellingDetail} from '../pages/user';
import { AdminDashboard } from "../pages/admin/AdminDashboard";
import { AdminLayout } from "../layout/AdminLayout";
import { AdminListing } from "../pages/admin/AdminListing";
import { AdminPackages } from "../pages/admin/AdminPackages";
import { AdminPages } from "../pages/admin/AdminPages";
import { ProfilePage } from "../pages/user/ProfilePage";
import { UserProfileLayout } from "../layout/UserProfileLayout";
import { AcquireDetailPage,
    BusinessExperiencePage,
    BusinessIndustryPage,
    BusinessModelPage,
    UserInfoPage,
    UserOnlineBusinessPage,
    UserTipsPage,
    UserWelcomePage,
    HowManyBusinessPage, 
    BudgetPage,
    DealRangePage,
    SellerLocationPage,
    UserDetailPage,
    CompletePage} from "../pages/signup";
import { UploadBulkListing } from "../pages/admin/UploadBulkListing";
import { AdminUserListing } from "../pages/admin/AdminUserListing";
import { NotFoundPage } from "../pages/public/NotFoundPage";
import { SearchPage } from "../pages/public/SearchPage";


export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<HomeLayout />}>
                <Route path="/" element={<HomePage />} exact />
            </Route>
            
            <Route path="/" element={<PublicLayout />}>
                <Route path="/:slug/:id"  element={<DetailPage />} />
                <Route path="/register" element={<SignupPage />} />
                
                <Route path="/login" element={<LoginPage />} />
                <Route path="/auth/verify-email" element={<VerifyEmailPage />} />
                <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/auth/reset-password/:token" element={<ResetPasswordPage />} />
                <Route path="/auth/reset-link" element={<ResetLinkSuccessPage />} />

                <Route path="/category/:slug" element={<CategoryListingPage />} />

                <Route path="/auctions" element={<AuctionPagePublic />} />
                <Route path="/offers" element={<OfferPagePublic />} />

                <Route path="/search" element={<SearchPage />} />

                <Route path="/contact-us" element={<ContactPage />} />
                <Route path="/term-of-service" element={<TermsPage />} />
                <Route path="/privacy-policy" element={<PrivacyPage />} />
            </Route>

            <Route path="/profile" element={<UserProfileLayout />}>
                <Route path="info/:id" element={<UserInfoPage />} />
                <Route path="welcome/:id" element={<UserWelcomePage />} />
                <Route path="tips/:id" element={<UserTipsPage />} />
                <Route path="online-business/:id" element={<UserOnlineBusinessPage />} />
                <Route path="business-experience/:id" element={<BusinessExperiencePage />} />
                <Route path="business-model/:id" element={<BusinessModelPage />} />
                <Route path="business-industry/:id" element={<BusinessIndustryPage />} />
                <Route path="acquire-detail/:id" element={<AcquireDetailPage />} />
                <Route path="how-many-business/:id" element={<HowManyBusinessPage />} />
                <Route path="budget/:id" element={<BudgetPage />} />
                <Route path="deal-range/:id" element={<DealRangePage />} />
                <Route path="seller-location/:id" element={<SellerLocationPage />} />
                <Route path="user-detail/:id" element={<UserDetailPage />} />
                <Route path="complete/:id" element={<CompletePage />} />
            </Route>

            <Route path="/user" element={<UserLayout />} exact>
                <Route path="dashboard" element={<DashboardPage />}  />
                <Route path="auctions" element={<AuctionPage />} />
                <Route path="offers" element={<OfferPage />} />
                <Route path="profile" element={<ProfilePage />} />
                
                <Route path="selling" element={<SellingPage />}  />
                <Route path="drafted" element={<DraftedPage />}  />

                <Route path="conversations" element={<ConversationPage />}  />
                <Route path="conversation/:conversationId/:receiverId/:listingId" element={<ThreadPage />}  />
                
                <Route path="watchlist" element={<WatchPage />}  />
                <Route path="buying" element={<BuyPage />}  />
                
                <Route path="listings/type" element={<ListingType />} />
                <Route path="listings/website/:id" element={<WebsiteUrl />} />
                <Route path="listings/business/:id" element={<WebsiteBusiness />} />
                <Route path="listings/financial/:id" element={<WebsiteFinancial />} />
                <Route path="listings/monetization/:id" element={<WebsiteMonetization />} />
                <Route path="listings/integration/:id" element={<WebsiteIntegrations />} />
                <Route path="listings/meta/:id" element={<WebsiteMeta />} />
                <Route path="listings/description/:id" element={<WebsiteDescription />} />
                <Route path="listings/social/:id" element={<WebsiteSocial />} />
                <Route path="listings/images/:id" element={<WebsiteImages />} />

                <Route path="listings/google-accounts/:id" element={<IntegrationAccount />} />
                
                <Route path="listings/domain/:id" element={<DomainUrl />} />
                <Route path="listings/domain-business/:id" element={<DomainBusiness />} />
                <Route path="listings/domain-meta/:id" element={<DomainMeta />} />
                <Route path="listings/domain-description/:id" element={<DomainDescription />} />
                <Route path="listings/domain-cover/:id" element={<DomainCover />} />

                <Route path="listings/offers/:id" element={<SellingDetail />} />
                
                <Route path="listings/app/:id" element={<AppPage />} />
                <Route path="listings/sell-type/:id" element={<SellOption />} />
                <Route path="listings/pay/:id" element={<PaymentPage />} />
            </Route>

            <Route path="/admin" element={<AdminLayout />} exact>
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="listings" element={<AdminListing />} />
                <Route path="upload-listings" element={<UploadBulkListing />} />
                <Route path="users" element={<AdminUserListing />} />
                <Route path="packages" element={<AdminPackages />} />
                <Route path="pages" element={<AdminPages />} />

            </Route>


            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}