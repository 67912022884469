import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";



import { ErrorLabel, Spinner } from "../../components";
import data from '../../assets/data/countries.json'
import ListingService from "../../services/ListingService";


import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { WebsiteLinks } from "./WebsiteLinks";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";


export const WebsiteBusiness = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [countries, setCountries] = useState([])
    const [categories, setCategories] = useState([]);
    const [step, setStep] = useState(2);
    const [title, setTitle] = useState('Your business details');
    const showToast = useToast();


    const { register, handleSubmit, formState: { errors}, reset, } = useForm();

    const onSubmit = data => {
        setSpinner(true)
        data.type = 'website';
        data['step'] =  step;
        ListingService.update(id, data)
        .then((res) => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            setStep(res.step);
            navigate(`/user/listings/financial/${id}`);
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {

            if (!res.data.business_registered_country) {
                res.data['business_registered_country'] = 'United Kingdom'
            }

            if (!res.data.industry && categories && categories.length) {
                res.data['industry'] = categories[0].id
            }

            if (!res.data.age) {
                res.data['age'] = 1
            }

            reset(res.data);
            setStep(res.data?.step > step ? res.data?.step : step);
            setTitle(res.data.website_business_name ? res.data.website_business_name + ' - Business Information' : ' Business Information')
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })

        ListingService.categories()
        .then(res => {
            setCategories(res.data);
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })

        setCountries(data);        

    }, []);

    
    usePageTitle(title);

    return (

        <Row>
            <Col lg={3}>
                <WebsiteLinks id={id} step={step}  current={2} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                    Business Information
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col lg={8}>
                                    <Form.Label>Business Name</Form.Label>
                                    <Form.Control type="text" size="lg" 
                                        {...register('website_business_name', {
                                            required: true,
                                        })}
                                    />
                                    {(errors.website_business_name) && <ErrorLabel >Business name is required</ErrorLabel>}
                                </Col>

                                <Col lg={4}>
                                    <Form.Label >How old is your Domain</Form.Label>
                                    <Form.Control 
                                        type="number" size="lg"
                                        {...register('age', {
                                            required: true,
                                        })}
                                    />
                                    {(errors.age) && <ErrorLabel >Domain age (number) is required </ErrorLabel>}
                                </Col>
                            </Row>

                            <Row className="my-3">
                                <Col lg={6}>
                                    <Form.Label>Business registered in</Form.Label>
                                    <Form.Select  size="lg" 
                                        {...register('business_registered_country', {
                                            required: true,
                                        })}
                                        name="business_registered_country"
                                        defaultValue={'United States'}
                                        >
                                            {countries.length && countries.map(country => (
                                            
                                                <option  key={country.code} value={country.name}>{country.name}</option>
                                            ))}
                                            
                                    </Form.Select>
                                    {(errors.business_registered_country) && <ErrorLabel >Country is required</ErrorLabel>}
                                </Col>

                                <Col lg={6}>
                                    <Form.Label>Category</Form.Label>
                                    <Form.Select  size="lg"
                                        {...register('industry', {
                                            required: true,
                                        })}
                                        name="industry"
                                        >
                                            {categories.length && categories.map(category => (
                                                <option  key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                    </Form.Select>
                                    
                                    {(errors.industry) && <ErrorLabel >Industry is required</ErrorLabel>}
                                </Col>
                            </Row>

                           
                            <Row>
                                <Col lg={12} className="d-flex justify-content-between">
                                    <Link className="my-4 btn btn-secondary"  to={`/user/listings/website/${id}`}> Back</Link>
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>
                            

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        


    )
}