import { useEffect, useState } from "react";
import { Badge, Card } from "react-bootstrap";
import moment from"moment";


import { Link } from "react-router-dom";

import { usePageTitle } from "../../hooks/usePageTitle";


import ListingService from "../../services/ListingService";
import { Spinner } from "../../components";
import { ConfirmDialog } from "primereact/confirmdialog";

export const SellingPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Manage Listings');
    const [listings, setListings] = useState(null);
    const [spinner, setSpinner] = useState(true);

    useEffect(()=> {
        ListingService.completed()
        .then(res => {
            setSpinner(false)
            setListings(res.data)
        })
        .catch(e => {
            setSpinner(false)
            console.log(e);
        })
    }, []);


    return(
        <Card className="shadow-sm border-0">
            <Card.Header className="border-0 h2">
                Active Listings

                <Link className="btn btn-sm btn-success float-right " to="/user/drafted">Drafted</Link>
                <Link className="btn btn-sm btn-success float-right mx-3" to="/user/listings/type">Post a Listing</Link>
            </Card.Header>
            <Card.Body>

                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Type</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Expiring</th>
                            <th>Offers</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {listings && listings.data.map((item) => (
                        <tr key={item.id} >
                            <td>{item.id}</td>
                            <td><Badge bg="success" className="m-1 ">{item.type}</Badge></td>
                            <td>{item.website_business_name}</td>
                            <td>
                                {item.status === 1 ? <Badge bg="success" className="m-1">Active</Badge> : <Badge bg="danger" className="m-1">Inactive</Badge>}
                            </td>
                            <td>{ moment(item.expire_date, "YYYYMMDDh").fromNow() }</td>
                            <td>
                                {item.bids.length 
                                    ? ( <Link className="btn btn-sm btn-outline-success" to={`/user/listings/offers/${item.id}`} > {item.bids.length} Offers</Link>) 
                                    : ('No offer yet')}
                            </td>
                            <td>
                                <Link to={`/user/listings/${item.type}/${item.id}`} className="btn btn-sm btn-outline-primary">Edit listing</Link>
                                

                            </td>
                        </tr>
                    ))}
                        
                    </tbody>
                </table>
            </Card.Body>
            {spinner && <Spinner />}

            <ConfirmDialog />
        </Card>
    );
}