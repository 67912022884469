import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";

import { Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useToast } from "../../hooks/ToastContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";


export const LoginPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Login');

    const { register, handleSubmit, formState: { errors } } = useForm();
    const location = useLocation();
    const [redirectUrl, setRedirectUrl] = useState(null);

    useEffect(()=> {
        setRedirectUrl(new URLSearchParams(location.search).get('redirect') || '/user/dashboard')
    }, [location.search])

    const [spinner, setSpinner] = useState(false);
    const [serverError, setServerError] = useState([]);
    const { login } = useAuth();
    const navigate = useNavigate();
    const showToast = useToast();
    
    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {
          handleGoogleAuth(jwtDecode(credentialResponse.credential))
        },
        onError: () => {
          console.log('Login Failed');
        },
    });
    
    const onSubmit = (data) => {
        setServerError([])
        setSpinner(true)
        AuthService.login(data)
        .then(async ({data}) => {
            setSpinner(false);
            await login(data);
            if (data.user?.is_admin){
                navigate('/admin/dashboard')
            } else {
                navigate(redirectUrl);
            }
        })
        .catch(e =>{
            setSpinner(false)
            setServerError(e.response?.data?.message);
        });
    }

    const handleGoogleAuth = (user) => {
        AuthService.googleAuth(user)
        .then(async (res) => {
            setSpinner(false)
            await login(res);
            if (res.user?.is_admin){
                navigate('/admin/dashboard');
            } else {
                navigate(redirectUrl);
            }
            
        })
        .catch(e =>{
            setSpinner(false)
            setServerError(e.response?.data?.errors);
        });
    }

    const onLogin = useGoogleLogin({
        flow: 'implicit',
        scope: 'openid email profile',
        onSuccess: async (tokenResponse) => {
            const token = tokenResponse.access_token;
            try {
                const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
        
                const userInfo = userInfoResponse.data;
                handleGoogleAuth(userInfo)
            } catch (error) {
                console.error('Failed to fetch user information:', error);
                showToast({severity: 'error', detail: 'Failed to fetch user information'});
            }
        },
      });

    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => {
        setShowPassword((prevIsActive) => !prevIsActive); 
    }

    return (
        <div className="form-signin w-100 m-auto">

            {spinner && <Spinner />}

            <Card>
                <CardBody>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1 className="h3 mb-3 fw-normal">Login to your account</h1>
                        { serverError && <div className="text-danger text-center">{serverError}</div>}


                        <div className="form-group">
                            <label htmlFor="floatingInput">Email</label>
                            <input type="text" 
                                    className="form-control" 
                                    id="floatingInput" 
                                    placeholder="name@example.com"
                                    {...register('email', {
                                        required: true,
                                        pattern: {
                                            value: /[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9A-Z])?\.)+[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9])?/,
                                            message: 'Enter a valid email address'
                                        }
                                    })} 
                            />

                            {(errors.email && errors.email.type==='required') && <div className='text-danger'>Email is required</div>}
                            {(errors.email?.message) && <div className='text-danger'>{errors.email?.message}</div>}
                            
                        </div>
                        
                        <div className="form-group mt-3">
                            <label>Password</label>
                            <div className="input-group">
                                <input type={showPassword ? 'text' : 'password'}
                                    className="form-control"
                                    placeholder="Password"
                                    {...register('password', {required: true, 
                                        maxLength: 100,
                                        minLength: {
                                            value: 6,
                                            message: 'Password must be at least 6 character long'
                                        }})} 
                                />

                                <div className="input-group-append _append-text">
                                    <button className="btn" onClick={togglePassword} type="button">{ showPassword ? <FaEyeSlash  /> : <FaEye />  } </button>
                                </div>
                            </div>

                        {(errors.password && errors.password.type==='required') && <div className='text-danger'>Password is required</div>}
                        {(errors.password?.message) && <div className='text-danger'>{errors.password?.message}</div>}
                            
                        </div>


                        <div className="d-grid gap-2 mt-3">
                            <button className="btn btn-primary" type="submit">Sign in</button>

                            <div className="mt-3 d-flex justify-content-between text-muted">
                                <Link to="/register">Create a new account</Link> | <Link to="/auth/forgot-password">Forgot you password?</Link>
                            </div>
                            
                            <div className="or-separator">
                                <span>OR</span>
                            </div>

                            <button className="btn btn-outline-secondary" onClick={()=> onLogin()} type="button"> 
                                <i className="google-icon"></i> Google Login
                            </button>                            
                        </div>
                        
                    </form>
                </CardBody>
            </Card>
            
            
        </div>
    );
}